import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleText from "../components/text/title-text/title-text"
import ParagraphText from "../components/text/paragraph-text/paragraph-text"
import BackgroundImage from "gatsby-background-image"
import { StaticQuery, graphql } from "gatsby"

import styles from "./seguros-de-salud.module.scss"

function InsurancePage() {
  return (
    <Layout>
      <SEO title="Seguros de salud" />
      <TitleText text="Seguros de salud" />
      <StaticQuery
        query={graphql`
          query {
            desktop: file(relativePath: { eq: "insurance.jpg" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <BackgroundImage
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              fluid={data.desktop.childImageSharp.fluid}
              backgroundColor={`#ffffff`}
            >
              <div className={styles.container}>
                <h5 className={styles.text}>Un animal: 15€/mes</h5>
                <br />
                <h5 className={styles.text}>
                  Segundo animal y siguientes: 14€/mes
                </h5>
              </div>
            </BackgroundImage>
          )
        }}
      />
      <br />
      <ParagraphText
        text={` Los seguros de salud son semejantes a los seguros médicos privados que se contratan para las personas. Mediante el pago de una cantidad fija mensual, se dispone de los servicios médicos que necesiten nuestras mascotas con numerosas ventajas.`}
      />

      <div className={styles.wrapper}>
        <div className={(styles.box, styles.box1)}>
          <p>Gratuito</p>
        </div>
        <div className={(styles.box, styles.box11)}>
          <p>Consultas y revisiones</p>
        </div>
        <div className={(styles.box, styles.box2)}>
          <p>50% de descuento</p>
        </div>
        <div className={(styles.box, styles.box3)}>
          <p>Vacunación perros: rabia, polivalente y puppy </p>
        </div>
        <div className={(styles.box, styles.box4)}>
          <p>Vacunación gatos: rabia, trivalente, leucemia felina y PIF </p>
        </div>
        <div className={(styles.box, styles.box5)}>
          <p>
            Analíticas: análisis de sangre, análisis de orina, análisis de
            heces, citologías, etc
          </p>
          <p>Radiografías</p>
          <p>Ecografías abdominales</p>
          <p>Electrocardiogramas</p>
        </div>
        <div className={(styles.box, styles.box6)}>
          <p>Identificación por microchip</p>
        </div>
        <div className={(styles.box, styles.box7)}>
          <p>25% de descuento</p>
        </div>
        <div className={(styles.box, styles.box8)}>
          <p>Tratamientos quirúrgicos</p>
          <p>Hospitalizaciones diurnas</p>
        </div>
        <div className={(styles.box, styles.box9)}>
          <p>10% de descuento</p>
        </div>
        <div className={(styles.box, styles.box10)}>
          <p>Alimentación seca</p>
        </div>
      </div>
    </Layout>
  )
}

export default InsurancePage
